const apiUrl = process.env.REACT_APP_API_URL.replace(/\/$/, "");

// Fetch wrappers
class Request {
  constructor(options) {
    const { url, headers, body } = options;
    this.url = url;
    this.method = "";

    this.headers = new Headers();
    headers &&
      Object.entries(headers).forEach((entry) => {
        let [key, value] = entry;
        this.headers.append(key, value);
      });

    this.body = new FormData();
    body &&
      Object.entries(body).forEach((entry) => {
        const [key, value] = entry;
        Array.isArray(value)
          ? value.forEach((item) => this.body.append(key, item))
          : this.body.append(key, value);
      });
  }
  async sendBlob(success = function () {}, failure = function () {}) {
    const options = {
      method: this.method,
      headers: this.headers,
      redirect: "follow",
    };

    if (!!this.body) options.body = this.body;

    try {
      const response = await fetch(this.url, options);
      const result = await response.blob();
      if (!response.ok) {
        failure(result);
        return;
      }
      return success(result);
    } catch (error) {
      return failure(error);
    }
  }

  async send(success = function () {}, failure = function () {}) {
    if (!success) success = function () {};
    if (!failure) failure = function () {};

    const options = {
      method: this.method,
      headers: this.headers,
      redirect: "follow",
    };

    if (!!this.body) options.body = this.body;

    try {
      const response = await fetch(this.url, options);
      const result = await response.json();
      if (!response.ok) {
        failure(result);
        return;
      }
      return success(result);
    } catch (error) {
      return failure(error);
    }
  }
}

class Get extends Request {
  constructor(url, headers) {
    super(url, headers);
    this.method = "GET";
    this.body = undefined;
  }
}

class Post extends Request {
  constructor(url, headers, body) {
    super(url, headers, body);
    this.method = "POST";
  }
}

class Delete extends Request {
  constructor(url, headers) {
    super(url, headers);
    this.method = "DELETE";
    this.body = undefined;
  }
}

class Patch extends Request {
  constructor(url, headers, body) {
    super(url, headers, body);
    this.method = "PATCH";
  }
}

// Requests
// Register
export const createUser = (username, success, failure) =>
  new Post({
    url: `${apiUrl}/create`,
    body: {
      username,
    },
  }).send(success, failure);

const verifyUser = (username, code, success, failure) =>
  new Post({
    url: `${apiUrl}/verify`,
    body: {
      username,
      code,
    },
  }).send(success, failure);

// Send files
export const getUsername = (jwt, success) =>
  new Get({
    url: `${apiUrl}/user`,
    headers: {
      Authorization: jwt,
    },
  }).send((result) => success(result.user));

export const verifyUpload = (code, files, types, from, to, success, failure) =>
  verifyUser(
    from,
    code,
    (result) =>
      createAnonymousProject(
        result.token,
        files,
        types,
        from,
        to,
        success,
        failure
      ),
    failure
  );

export const createAnonymousProject = (
  jwt,
  files,
  types,
  from,
  to,
  success,
  failure
) =>
  new Post({
    url: `${apiUrl}/projects`,
    headers: {
      Authorization: jwt,
    },
  }).send(
    (result) =>
      sendAnonymousFiles(
        jwt,
        result.project,
        files,
        types,
        from,
        to,
        success,
        failure
      ),
    failure
  );

const sendAnonymousFiles = (
  jwt,
  project,
  files,
  types,
  from,
  to,
  success,
  failure
) =>
  new Post({
    url: `${apiUrl}/files`,
    headers: {
      Authorization: jwt,
    },
    body: {
      bim: files,
      tag: types,
      project,
    },
  }).send(
    (result) => sendMail(jwt, project, from, to, success, failure),
    failure
  );

const sendMail = (jwt, project, from, to, success, failure) =>
  new Post({
    url: `${apiUrl}/email`,
    headers: {
      Authorization: jwt,
    },
    body: {
      from,
      to,
      project,
    },
  }).send(success, failure);

// Login modal
export const authenticate = (username, password, remember, success, failure) =>
  new Post({
    url: `${apiUrl}/authenticate`,
    body: {
      username,
      password,
      infinite: true,
    },
  }).send((result) => {
    remember
      ? localStorage.setItem("auth", result.token)
      : sessionStorage.setItem("auth", result.token);

    success && success();
  }, failure);

export const verifyRegister = (
  code,
  username,
  password,
  name,
  profession,
  sector,
  success,
  failure
) =>
  verifyUser(
    username,
    code,
    (result) =>
      register(
        result.token,
        username,
        password,
        name,
        profession,
        sector,
        success,
        failure
      ),
    failure
  );

const register = (
  jwt,
  username,
  password,
  name,
  profession,
  sector,
  success,
  failure
) =>
  new Post({
    url: `${apiUrl}/register`,
    headers: {
      Authorization: jwt,
    },
    body: {
      username,
      password,
      name,
      profession,
      sector,
    },
  }).send(
    (result) => authenticate(username, password, false, success, failure),
    failure
  );

// Projects manager
export const createProject = (jwt, name, success) =>
  new Post({
    url: `${apiUrl}/projects`,
    headers: {
      Authorization: jwt,
    },
    body: {
      name,
    },
  }).send((result) => getProjects(jwt, success));

export const getProjects = (jwt, success) =>
  new Get({
    url: `${apiUrl}/projects`,
    headers: {
      Authorization: jwt,
    },
  }).send((result) => success(result.projects));

export const getFiles = (jwt, project, success) =>
  new Get({
    url: `${apiUrl}/projects/${project}`,
    headers: {
      Authorization: jwt,
    },
  }).send((result) => success(result.files));

export const uploadFile = (jwt, project, name, file, type, success) =>
  new Post({
    url: `${apiUrl}/files`,
    headers: {
      Authorization: jwt,
    },
    body: {
      bim: file,
      tag: type,
      name,
      project,
    },
  }).send(success);

export const deleteFile = (jwt, id, success) =>
  new Delete({
    url: `${apiUrl}/files/${id}`,
    headers: {
      Authorization: jwt,
    },
  }).send(success);

export const updateFile = (jwt, id, data, success) =>
  new Patch({
    url: `${apiUrl}/files/${id}`,
    headers: {
      Authorization: jwt,
    },
    body: data,
  }).send(success);

export const updateProject = (jwt, id, data, success) =>
  new Patch({
    url: `${apiUrl}/projects/${id}`,
    headers: {
      Authorization: jwt,
    },
    body: data,
  }).send(success);

export const deleteProject = (jwt, id, success) =>
  new Delete({
    url: `${apiUrl}/projects/${id}`,
    headers: {
      Authorization: jwt,
    },
  }).send(success);

export const getSensors = (jwt, file, success) =>
  new Get({
    url: `${apiUrl}/sensors/${file}`,
    headers: {
      Authorization: jwt,
    },
  }).send((result) => success(result.sensors));

export const saveSensors = (jwt, file, sensors, success) =>
  new Post({
    url: `${apiUrl}/sensors/${file}`,
    headers: {
      Authorization: jwt,
    },
    body: {
      sensors: JSON.stringify(sensors),
    },
  }).send((result) => success());

export const getSensorsByProject = (project, success) =>
  new Get({
    url: `${apiUrl}/all_sensors/${project}`,
  }).send((result) => success(result.sensors));

// Project viewer
export const getExcel = (project, file, success, failure) =>
  new Get({
    url: `${apiUrl}/excel/${project}/${file}`,
  }).send(
    (result) =>
      result.status === 200
        ? staticFile(result.path, success, failure)
        : failure(),
    (error) => failure(error)
  );

export const getZip = (project, success, failure) =>
  new Get({
    url: `${apiUrl}/zip/${project}`,
  }).send(
    (result) =>
      result.status === 200
        ? staticFile(result.path, success, failure)
        : failure(),
    (error) => failure(error)
  );

export const saveBcf = (project, bcf, success, failure) =>
  new Post({
    url: `${apiUrl}/bcf/${project}`,
    body: {
      bcf: JSON.stringify(bcf),
    },
  }).send(success, failure);

export const getRoutes = (project, success, failure) =>
  new Get({
    url: `${apiUrl}/routes/${project}`,
  }).send(success, failure);

export const staticFile = (url, success, failure) =>
  new Get({
    url: `${apiUrl}/static/${url}`,
  }).sendBlob(success, failure);

export const staticFileJson = (url, success, failure) =>
  new Get({
    url: `${apiUrl}/static/${url}`,
  }).send(success, failure);
