import { createMuiTheme } from "@material-ui/core/styles";
import colors from "../interior/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#3d3d3d",
      main: colors.banner,
      dark: "#000000",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff153",
      main: colors.primary,
      dark: "#c58f00",
      contrastText: "#191919",
    },
    error: {
      light: "#ff5332",
      main: colors.error,
      dark: "#a50000",
      contrastText: "#fff",
    },
  },
});

export default theme;
