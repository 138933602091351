import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { interiorTheme } from "../common/src/theme/interior";
import { DrawerProvider } from "../common/src/contexts/DrawerContext";
import Navbar from "../containers/Interior/Navbar";
import Banner from "../containers/Interior/Banner";
import Feature from "../containers/Interior/Feature";
import AboutUs from "../containers/Interior/AboutUs";
//import Project from "../containers/Interior/Project";
import Team from "../containers/Interior/Team";
//import News from "../containers/Interior/News";
import Testimonial from "../containers/Interior/Testimonial";
import Gallery from "../containers/Interior/Gallery";
import Footer from "../containers/Interior/Footer";
import { ResetCSS } from "../common/src/assets/css/style";
import {
  GlobalStyle,
  InteriorWrapper,
  ContentWrapper,
} from "../containers/Interior/interior.style";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getUsername } from "../common/src/api";

const Landing = ({ openModal, tabIndex, ...props }) => {
  const params = new URLSearchParams(props.location.search);
  const auth = localStorage.getItem("auth") || sessionStorage.getItem("auth");
  const [username, setUsername] = React.useState(undefined);
  const [bannerOpenModal, setBannerOpenModal] = React.useState(false);

  React.useEffect(() => {
    if (auth) getUsername(auth, (u) => setUsername(u));
  }, [auth]);

  const [stickyActive, setStickyActive] = React.useState(false);

  const handleStickyState = (status) =>
    setStickyActive(status.status !== Sticky.STATUS_ORIGINAL);

  const handleOpenModal = () => setBannerOpenModal(true);

  const handleCloseModal = () => setBannerOpenModal(false);

  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
        <ResetCSS />
        <GlobalStyle />

        {/* Start writing your markup from here. */}
        <InteriorWrapper>
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            onStateChange={handleStickyState}
          >
            <DrawerProvider>
              <Navbar
                connected={!!auth}
                stickyActive={stickyActive}
                openModal={bannerOpenModal || openModal}
                onCloseModal={handleCloseModal}
                tabIndex={bannerOpenModal ? 0 : tabIndex}
                user={params.get("email")}
              />
            </DrawerProvider>
          </Sticky>
          <ContentWrapper>
            <DndProvider backend={HTML5Backend}>
              <Banner email={username} openModal={handleOpenModal} />
            </DndProvider>
            <Feature />
            <AboutUs />
            {/*<Project />*/}
            <Team />
            {/*<News />*/}
            <Testimonial />
            <Gallery />
          </ContentWrapper>
          <Footer />
        </InteriorWrapper>
        {/* End of markup section. */}
      </Fragment>
    </ThemeProvider>
  );
};

export default Landing;
