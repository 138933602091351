import slide1 from "../../assets/image/interior/slider/web-Bim-Share-1.png";
import slide2 from "../../assets/image/interior/slider/web-Bim-Share-2.png";

import corporateIcon from "../../assets/image/interior/feature/icono-ifc.svg";
import landscapeIcon from "../../assets/image/interior/feature/icono-3d.svg";
import interiorIcon from "../../assets/image/interior/feature/icono-otras-funciones.svg";

import { facebook } from "react-icons-kit/fa/facebook";
import { dribbble } from "react-icons-kit/fa/dribbble";
import { googlePlus } from "react-icons-kit/fa/googlePlus";
import { skype } from "react-icons-kit/fa/skype";
import { twitter } from "react-icons-kit/fa/twitter";
import logo from "../../assets/image/interior/logo.svg";

import galleryImg1 from "../../assets/image/interior/gallery/PlanOK.png";
import galleryImg2 from "../../assets/image/interior/gallery/BIWISE.jpg";
import galleryImg3 from "../../assets/image/interior/gallery/IGEO_BIM_2.jpg";
import galleryImg4 from "../../assets/image/interior/gallery/VelociTI.jpg";
import galleryImg5 from "../../assets/image/interior/gallery/DTP_BIM_1.jpg";

import { Icon } from "react-icons-kit";
/*
import { socialFacebook } from "react-icons-kit/ionicons/socialFacebook";
import { socialDribbble } from "react-icons-kit/ionicons/socialDribbble";
import { socialGoogleplus } from "react-icons-kit/ionicons/socialGoogleplus";
import { socialSkype } from "react-icons-kit/ionicons/socialSkype";
import { socialTwitter } from "react-icons-kit/ionicons/socialTwitter";
*/

import member1 from "../../assets/image/interior/team/ARC_.jpg";
import member2 from "../../assets/image/interior/team/STR_.jpg";
import member3 from "../../assets/image/interior/team/MEP_.jpg";

import parentImg from "../../assets/image/interior/MODELO CENTRAL.jpg";

import project1 from "../../assets/image/interior/projects/1.png";
/* interior dummy data list :-
- Navbar
- Banner
- Feature
- About
- Projects
- Team
- News
- Testimonial
- Gallery
- Newsletter
- Footer
  - menu widget
  - copyright
  - social links
*/
/* ------------------------------------ */
// Menu data
/* ------------------------------------ */
export const menuData = [
  {
    label: "Inicio",
    path: "#home",
    offset: "80",
  },
  {
    label: "Acerca de",
    path: "#about",
    offset: "80",
  },
  {
    label: "Colaboradores",
    path: "#collaborations",
    offset: "40",
  },
];

/* ------------------------------------ */
// Banner section data
/* ------------------------------------ */
export const bannerData = {
  discount: "BETA",
  discountLabel: "COMPARTE Y VISUALIZA MODELOS",
  title: "BIM al alcance de todos",
  text:
    "Carga un modelo IFC y añade los correos para compartirlo con quien tú quieras. Podrás ver el modelo, sus distintas especialidades y crear observaciones.",
  carousel: [
    {
      id: 1,
      thumb_url: slide1,
      title: "",
      link: "#1",
    },
    {
      id: 2,
      thumb_url: slide2,
      title: "",
      link: "#2",
    },
  ],
};

/* ------------------------------------ */
// Feature section data
/* ------------------------------------ */

export const featureData = {
  title: "¿QUÉ ES?",
  slogan:
    "BIMshare es una plataforma para visualizar y compartir tus modelos BIM de manera fácil, rápida y gratuita.",
  features: [
    {
      id: 1,
      icon: corporateIcon,
      title: "Visualiza y Comparte tus IFC",
      description:
        "Permite la colaboración entre distintos usuarios para un mismo conjunto de modelos relacionados.",
    },
    {
      id: 2,
      icon: landscapeIcon,
      title: "Revisa y Observa en 3D",
      description:
        "Podrás añadir observaciones, calcular la distancia entre dos puntos y varias herramientas de navegación.",
    },
    {
      id: 3,
      icon: interiorIcon,
      title: "Otras funciones",
      description:
        "Descarga las observaciones en BCF o PDF. Extrae la data asociada al Modelo BIM en formato XLS (Excel).",
    },
  ],
};

/* ------------------------------------ */
// About section data
/* ------------------------------------ */

export const aboutData = {
  thumb_url: parentImg,
  title: "Proyectos colaborativos",
  text:
    "BIMshare fomenta la colaboración entre profesionales de distintas especialidades que trabajan entorno a un mismo proyecto BIM.",
  text2:
    "Con BIMshare puedes cargar tantos modelos como quieras para verlos de forma simultánea, ocultando modelos o elementos según tu necesidad, o navegando como si fueras una persona en los diferentes niveles del edificio.",
  text3:
    "Sin importar el software en el cual hayas modelado, podrás compartir tu modelo con quien quieras a través del formato IFC BIM con el cual impulsamos la interoperabilidad.",
};

/* ------------------------------------ */
// Projects section data
/* ------------------------------------ */

export const projectData = {
  title: "WORK PROCEDURE",
  slogan: "How We Do Our Projects",
  thumb_url: project1,
  projects: [
    {
      id: 1,
      text:
        "Create a mind map to brainstorm your project to inspire new ideas. Use a simple piece of paper, poster, or whiteboard for your mind map and we write the destination of your project in the middle.We  Write down related topics, subtopics, and relevant concepts around the goal and branch from them to create and follow different tangents.",
      text2:
        "When you conduct a group project, each group member can benefit from the ideas of others and gain new insights by brainstorming together.",
    },
    {
      id: 2,
      text:
        "Schedule a brainstorming session in a quiet place with few distractions. If you are working on a project alone, you can use group discussions with friends or colleagues to gain a new perspective on the topic. When you conduct a group project, each group member can benefit from the ideas of others and gain new insights by brainstorming together.",
      text2:
        "Completing a successful project requires a significant amount of time and work, which means evaluating your priorities and planning your course of action.",
    },
    {
      id: 3,
      text:
        "Create a mind map to brainstorm your project to inspire new ideas. Use a simple piece of paper, poster, or whiteboard for your mind map and we write the destination of your project in the middle.We  Write down related topics, subtopics, and relevant concepts around the goal and branch from them to create and follow different tangents.",
      text2:
        "When you conduct a group project, each group member can benefit from the ideas of others and gain new insights by brainstorming together.",
    },
  ],
};

/* ------------------------------------ */
// Team section data
/* ------------------------------------ */

export const teamData = {
  title: "OBSERVA SIMULTÁNEAMENTE MODELOS DE",
  slogan: "Arquitectura, Estructura y MEP",
  members: [
    {
      id: 1,
      avatar: member1,
      name: "ARC",
      designation: "Revisión de modelos arquitectónicos",
      social_links: [],
    },
    {
      id: 2,
      avatar: member2,
      name: "STR",
      designation: "Revisión de modelos estructurales",
      social_links: [],
    },
    {
      id: 3,
      avatar: member3,
      name: "MEP",
      designation: "Revisión de especialidades",
      social_links: [],
    },
  ],
};

/* ------------------------------------ */
// News section data
/* ------------------------------------ */
export const newsData = [
  {
    id: 1,
    title: "Corporate design is a crucial part of your brand.",
    excerpt:
      "Corporate design is a crucial part of your brand. Our approach corporate design is extraordinary. Please come and visit us",
    link_text: "Read",
    link_url: "#",
  },
  {
    id: 2,
    title: "Learn more about landscape plans, how to design them.",
    excerpt:
      "Learn more about landscape plans, how to design them, what to watch out for in your layout. We deign the appropriate layout for our clients to deliver best",
    link_text: "Read",
    link_url: "#",
  },
  {
    id: 3,
    title: "Discover our design ideas, beautiful photos for interior design.",
    excerpt:
      "Discover our design ideas, beautiful photos and how-to projects to create Interior Design. We try to reflect your personality on our design",
    link_text: "Read",
    link_url: "#",
  },
  {
    id: 4,
    title: "Interior design is an art and we are trying to paint monalisa.",
    excerpt:
      "With the best players in the designing sector, we explore a whole new world of interior design.Our approach corporate design is extraordinary",
    link_text: "Read",
    link_url: "#",
  },
];

/* ------------------------------------ */
// Testimonial data
/* ------------------------------------ */
export const testimonialData = {
  title: "AGRADECIMIENTOS",
  slogan: "Colaboran con BIMshare",
  reviews: [
    {
      id: 0,
      name: "Parker Joe",
      designation: "Co-Founder & CEO",
      username: "@amader craft",
      account_url: "#",
      comment:
        "Get working experience to work with this amazing team & in future want to work together for bright future projects and also make deposit to freelancer.",
      avatar:
        "https://tinyfac.es/data/avatars/7D3FA6C0-83C8-4834-B432-6C65ED4FD4C3-500w.jpeg",
    },
    {
      id: 1,
      name: "Britney havana",
      designation: "Co-Founder & CEO",
      username: "@Light’s craft",
      account_url: "#",
      comment:
        "Impressed with master class support of the team and really look forward for the future.Really, really well made! Love that each component is handmade and customised. Great Work :)",
      avatar: "https://randomuser.me/api/portraits/women/63.jpg",
    },
    {
      id: 2,
      name: "June Spears",
      designation: "Co-Founder & CEO",
      username: "@Eagle’s craft",
      account_url: "#",
      comment:
        "Get working experience to work with this amazing team & in future want to work together for bright future projects and also make deposit to freelancer.",
      avatar: "https://randomuser.me/api/portraits/women/44.jpg",
    },
    {
      id: 3,
      name: "Jessica Parker",
      designation: "Co-Founder & CEO",
      username: "@Earth’s craft",
      account_url: "#",
      comment:
        "Impressed with master class support of the team and really look forward for the future.Really, really well made! Love that each component is handmade and customised. Great Work !",
      avatar:
        "https://pbs.twimg.com/profile_images/1049383024306081792/cQkGbpRO.jpg",
    },
    {
      id: 4,
      name: "Django Dsuja",
      designation: "Co-Founder & CEO",
      username: "@Moon’s craft",
      account_url: "#",
      comment:
        "Impressed with master class support of the team and really look forward for the future.Really, really well made! Love that each component is handmade and customised. Great Work :)",
      avatar:
        "https://pbs.twimg.com/profile_images/974736784906248192/gPZwCbdS.jpg",
    },
  ],
};

/* ------------------------------------ */
// Gallery data
/* ------------------------------------ */

export const galleryData = [
  {
    id: 1,
    thumb_url: galleryImg1,
    name: "PlanOK",
    link: "https://www.planok.com/",
  },
  {
    id: 2,
    thumb_url: galleryImg2,
    name: "BWise",
    link: "https://www.bwisebim.com/",
  },
  {
    id: 3,
    thumb_url: galleryImg3,
    name: "IGEO",
    link: "http://www.igeo.cl/",
  },
  {
    id: 4,
    thumb_url: galleryImg4,
    name: "VelociTI",
    link: "https://www.velociti.cl/",
  },
  {
    id: 5,
    thumb_url: galleryImg5,
    name: "DTP",
    link: "#",
  },
];

/* ------------------------------------ */
// Newsletter data
/* ------------------------------------ */
export const newsletterData = {
  title: "SUBSCRIBE NEWSLETTER",
  slogan: "Don’t Miss Out Any Offer",
  note:
    "Note: Please call us at 12pm to 8am. otherwise our customer supporter will not available to reach your call, but you can drop mail anytime.",
};

/* ------------------------------------ */
// Footer data
/* ------------------------------------ */

export const footerData = {
  logo: logo,
  mail: "hello@redq.io",
  phone: "123-456-7890",
  socialLinks: [
    {
      id: 1,
      icon: <Icon icon={facebook} />,
      name: "facebook",
      link: "#",
    },
    {
      id: 2,
      icon: <Icon icon={dribbble} />,
      name: "dribbble",
      link: "#",
    },
    {
      id: 3,
      icon: <Icon icon={googlePlus} />,
      name: "googlePlus",
      link: "#",
    },
    {
      id: 4,
      icon: <Icon icon={skype} />,
      name: "skype",
      link: "#",
    },
    {
      id: 5,
      icon: <Icon icon={twitter} />,
      name: "twitter",
      link: "#",
    },
  ],
  menuWidgets: [
    {
      id: 1,
      title: "ABOUT US",
      menu: [
        {
          id: 1,
          text: "Support Center",
          link: "#",
        },
        {
          id: 2,
          text: "Customer Support",
          link: "#",
        },
        {
          id: 3,
          text: "About Us",
          link: "#",
        },
        {
          id: 4,
          text: "Copyright",
          link: "#",
        },
        {
          id: 5,
          text: "Popular Campaign",
          link: "#",
        },
      ],
    },
    {
      id: 2,
      title: "OUR INFORMATION",
      menu: [
        {
          id: 1,
          text: "Return Policy",
          link: "#",
        },
        {
          id: 2,
          text: "Privacy Policy",
          link: "#",
        },
        {
          id: 3,
          text: "Terms & Conditions",
          link: "#",
        },
        {
          id: 4,
          text: "Site Map",
          link: "#",
        },
        {
          id: 5,
          text: "Store Hours",
          link: "#",
        },
      ],
    },
    {
      id: 3,
      title: "MY ACCOUNT",
      menu: [
        {
          id: 1,
          text: "Press inquiries",
          link: "#",
        },
        {
          id: 2,
          text: "Social media directories",
          link: "#",
        },
        {
          id: 3,
          text: "Images & B-roll",
          link: "#",
        },
        {
          id: 4,
          text: "Permissions",
          link: "#",
        },
        {
          id: 5,
          text: "Speaker requests",
          link: "#",
        },
      ],
    },
    {
      id: 4,
      title: "POLICY",
      menu: [
        {
          id: 1,
          text: "Application security",
          link: "#",
        },
        {
          id: 2,
          text: "Software principles",
          link: "#",
        },
        {
          id: 3,
          text: "Unwanted software policy",
          link: "#",
        },
        {
          id: 4,
          text: "Responsible supply chain",
          link: "#",
        },
      ],
    },
  ],
};

/* ------------------------------------ */
// social profile
/* ------------------------------------ */
export const socialProfile = [
  {
    id: 1,
    icon: "flaticon-facebook-logo",
    link: "#",
  },
  {
    id: 2,
    icon: "flaticon-twitter-logo-silhouette",
    link: "#",
  },
  {
    id: 3,
    icon: "flaticon-instagram",
    link: "#",
  },
  {
    id: 4,
    icon: "flaticon-tumblr-logo",
    link: "#",
  },
  {
    id: 5,
    icon: "flaticon-dribble-logo",
    link: "#",
  },
];
