import React, { Fragment, useState, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { Icon } from "react-icons-kit";
import { iosEmailOutline } from "react-icons-kit/ionicons/iosEmailOutline";
import Heading from "../../../common/src/components/Heading";
import Text from "../../../common/src/components/Text";
import Image from "../../../common/src/components/Image";
import Button from "../../../common/src/components/Button";
import Input from "../../../common/src/components/Input";
import FileInput from "../../../common/src/components/FileInput";
import GlideCarousel from "../../../common/src/components/GlideCarousel";
import GlideSlide from "../../../common/src/components/GlideCarousel/glideSlide";
import { CircleLoader } from "../interior.style";
import BannerWrapper, {
  Container,
  ContentArea,
  HighlightedText,
  FormWrapper,
  ButtonGroup,
  CarouselArea,
  AuthWrapper,
} from "./banner.style";
import Chip from "@material-ui/core/Chip";
import ChipInput from "material-ui-chip-input";
import { makeStyles } from "@material-ui/core/styles";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import colors from "../../../common/src/theme/interior/colors";
import { bannerData } from "../../../common/src/data/Interior";
import {
  createUser,
  verifyUpload,
  createAnonymousProject,
} from "../../../common/src/api";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReactInputVerificationCode from "react-input-verification-code";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((props) => ({
  root: {
    background: "white",
    borderRadius: 4,
    border: (props) =>
      `1px solid ${props.invalid ? colors.error : colors.inactiveIcon}`,
    paddingTop: 11,
    paddingLeft: 11,
    paddingRight: 11,
    "&:focus-within": {
      border: "1px solid #fdbf10",
    },
  },
  inputRoot: {
    padding: 11,
    paddingTop: "0px !important",
    borderRadius: 4,
    "&:hover": {
      "&::before": {
        borderBottom: "none",
      },
      "&::after": {
        borderBottom: "none",
      },
    },
    "&::before": {
      borderBottom: "none",
    },
    "&::after": {
      borderBottom: "none",
    },
  },
  input: {
    padding: 0,
    fontSize: 16,
    height: "unset",
    lineHeight: 1.5,
  },
  colorSecondary: {
    background: colors.error,
  },
}));

const Backdrop = (props) => {
  const isActive = props.active;

  return isActive ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "rgba(0, 0, 0, 0.8)",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          border: "4px dashed #4E5865",
          background: "rgba(255, 255, 255, 0.8)",
          borderRadius: 24,
          color: "#4E5865",
          padding: "4em",
          textAlign: "center",
        }}
      >
        <GetAppIcon style={{ fontSize: "5rem" }} />
        <p>Arrastre los archivos aquí.</p>
        <span>Formatos aceptados: .ifc, .ifczip, .zip</span>
      </div>
    </div>
  ) : null;
};

const UploadForm = (props) => {
  const files = props.files;
  const classes = useStyles({ invalid: !props.chipsValid });
  const emailRegex = /^\w+([.\-+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const [showFeedback, setShowFeedback] = React.useState(false);

  const handleChipRender = (state, key) => {
    let value = state.value;
    let color = "default";
    if (value.length > 0 && !value.match(emailRegex)) {
      color = "secondary";
    }
    return (
      <Chip
        classes={{ colorSecondary: classes.colorSecondary }}
        key={key}
        label={value}
        color={color}
        onDelete={state.handleDelete}
        style={{ margin: "0 8px 8px 0" }}
      />
    );
  };

  return (
    <FormWrapper
      onSubmit={(e) => props.onSubmit(e, () => setShowFeedback(true))}
    >
      <FileInput
        files={files}
        onChange={props.onFileChange}
        onDelete={props.onFileDelete}
        onSelect={props.onTypeSelect}
        accept=".ifc,.ifczip,.zip"
        multiple
        required={files.filter((f) => !!f).length === 0}
      />
      <hr style={{ opacity: 0, marginBottom: 8, marginTop: 6 }} />
      <Input
        className={props.emailValid}
        value={props.emailValue}
        forcedValue={props.emailDisabled && props.emailValue}
        type="email"
        placeholder="Ingresa tu correo electrónico"
        icon={<Icon icon={iosEmailOutline} />}
        iconPosition="left"
        required
        onChange={props.onEmailChange}
        aria-label="email"
        disabled={props.emailDisabled}
      />
      <hr style={{ opacity: 0, margin: 8 }} />
      <ChipInput
        value={props.emails}
        classes={{
          root: classes.root,
          inputRoot: classes.inputRoot,
          input: classes.input,
        }}
        onAdd={props.onChipAdd}
        onChange={props.onChipChange}
        onDelete={props.onChipDelete}
        placeholder={props.chipPlaceholder}
        newChipKeyCodes={[188]}
        newChipKeys={["Comma"]}
        fullWidth={true}
        chipRenderer={handleChipRender}
        disableUnderline={true}
      />
      <Typography
        className="mt-3"
        style={{
          display: showFeedback ? "block" : "none",
        }}
      >
        Ya eres miembro de BIMshare, para compartir un modelo debes{" "}
        <span
          style={{
            cursor: "pointer",
            color: "cornflowerblue",
          }}
          onClick={(e) => {
            e.preventDefault();
            props.openModal();
          }}
        >
          iniciar sesión
        </span>
        .
      </Typography>
      <ButtonGroup>
        <Button
          isLoading={props.isLoading}
          disabled={props.isLoading}
          loaderColor={colors.heading}
          type="submit"
          colors="primaryWithBg"
          title="COMPARTIR"
        />
        <Button
          title="VER UN EJEMPLO"
          variant="textButton"
          onClick={() =>
            window.open(
              "https://bimshare.app/f0f9ec7055aa456ea87e479798bafc44",
              "_blank"
            )
          }
        />
      </ButtonGroup>
    </FormWrapper>
  );
};

const SuccessBox = (props) => (
  <div style={{ borderRadius: 4, background: "white", padding: 24 }}>
    <Heading className="text-center" as="h3" content={"¡Listo!"} />
    <Text
      className="text-center"
      style={{ color: "#888" }}
      content={
        "Se ha enviado un correo con los datos para revisar el proyecto."
      }
    />
    <ButtonGroup
      className="d-flex justify-content-center"
      style={{ marginTop: 16 }}
    >
      <Button
        className="mr-0"
        colors="primaryWithBg"
        title="ENVIAR OTRO"
        onClick={props.onClick}
      />
    </ButtonGroup>
  </div>
);

const PendingBox = (props) => {
  const [auth, setAuth] = useState("");

  return (
    <div style={{ borderRadius: 4, background: "white", padding: 24 }}>
      <Heading className="text-center" as="h3" content={"Verifica tu email"} />
      <Text
        className="text-center"
        style={{ color: "#888" }}
        content={`Hemos enviado un código a ${props.email}.`}
      />
      <AuthWrapper>
        <ReactInputVerificationCode
          placeholder="-"
          length={6}
          onChange={setAuth}
        />
      </AuthWrapper>
      <ButtonGroup
        className="d-flex justify-content-center"
        style={{ marginTop: 16 }}
      >
        <Button
          variant="textButton"
          title="Volver"
          onClick={props.onClickBack}
        />
        <Button
          className="mr-0"
          colors="primaryWithBg"
          title="VERIFICAR"
          isLoading={props.isLoading}
          disabled={props.isLoading}
          loaderColor={colors.heading}
          onClick={() => props.onClickSend(auth)}
        />
      </ButtonGroup>
    </div>
  );
};

const Banner = (props) => {
  const { discount, discountLabel, title, text, carousel } = bannerData;
  const glideOptions = {
    type: "carousel",
    perView: 1,
    gap: 20,
    breakpoints: {
      1200: {
        perView: 2,
      },
      667: {
        perView: 2,
      },
      480: {
        perView: 1,
      },
    },
  };
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [done, setDone] = useState(false);
  const [placeholder, setPlaceholder] = useState(
    "Ingresa uno o varios correos (separados por coma)"
  );
  const [state, setState] = useState({ email: "", valid: "" });
  const [emails, setEmails] = useState([]);
  const [chipsValid, setChipsValid] = useState(true);
  const [files, setFiles] = useState([]);
  const [fileTypes, setFileTypes] = useState([]);

  const emailRegex = /^\w+([.\-+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const fileRegex = React.useMemo(() => /(\.ifc|\.ifczip|\.zip)$/, []);

  useEffect(() => {
    setState((s) => ({ ...s, email: props.email || "" }));
  }, [props]);

  useEffect(() => {
    setLoading(true);
  }, []);

  // Drag and drop handlers
  const handleFileDrop = React.useCallback(
    (item) => {
      if (item) {
        const newFiles = item.files;
        const valid = newFiles.every((file) => fileRegex.test(file.name));
        if (valid) {
          const newTypes = newFiles.map((file) => "ARC");
          setFiles((f) => [...f, ...newFiles]);
          setFileTypes((t) => [...t, ...newTypes]);
        }
      }
    },
    [fileRegex, fileTypes, files]
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        handleFileDrop(item);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [props]
  );
  const isActive = canDrop && isOver;

  // Form handlers
  const handleChange = (e) => {
    if (e.match(emailRegex)) {
      if (e.length > 0) {
        setState({ ...state, email: e, valid: "valid" });
      }
    } else {
      if (e.length > 0) {
        setState({ ...state, email: e, valid: "invalid" });
      } else {
        setState({ ...state, email: e, valid: "" });
      }
    }
  };

  const handleFilesUpload = (e) => {
    let newFiles = Array.from(e.target.files);
    let newTypes = newFiles.map((e) => "ARC");
    setFiles([...files, ...newFiles]);
    setFileTypes([...fileTypes, ...newTypes]);
  };

  const handleFileDelete = (i) => {
    let filesCopy = [...files];
    let fileTypesCopy = [...fileTypes];
    delete filesCopy[i];
    delete fileTypesCopy[i];
    setFiles(filesCopy);
    setFileTypes(fileTypesCopy);
  };

  const handleFileSelectType = (i, newType) => {
    let fileTypesCopy = [...fileTypes];
    fileTypesCopy[i] = newType;
    setFileTypes(fileTypesCopy);
  };

  // Chip handlers
  const handleChangeChip = (e) => {
    if (e.length > 0) {
      setPlaceholder("");
    } else {
      setPlaceholder("Ingresa uno o varios correos (separados por coma)");
    }
  };

  const handleDeleteChip = (chip, index) => {
    let emailsCopy = [...emails];
    delete emailsCopy[index];
    emailsCopy = emailsCopy.filter((e) => !!e);
    setEmails(emailsCopy);
  };

  const handleAddChip = (chip) => {
    setEmails([...emails, chip]);
  };

  // Form submit
  const anonSubmitForm = (e, onError) => {
    e.preventDefault();
    setBtnLoading(true);
    let emailValid = emailRegex.test(state.email);
    let receiversValid = emails.length > 0;
    emails.forEach(
      (e) => (receiversValid = receiversValid && emailRegex.test(e))
    );
    let filesValid = files.length > 0;
    files.forEach(
      (f) => (filesValid = filesValid && (!f || fileRegex.test(f.name)))
    );

    setChipsValid(receiversValid);
    if (!emailValid || !receiversValid || !filesValid) {
      setBtnLoading(false);
      return;
    }

    createUser(
      state.email,
      () => {
        setPending(true);
        setBtnLoading(false);
      },
      () => {
        onError();
        setBtnLoading(false);
      }
    );
  };

  const submitForm = (e, _) => {
    e.preventDefault();
    setBtnLoading(true);
    let emailValid = emailRegex.test(state.email);
    let receiversValid = emails.length > 0;
    emails.forEach(
      (e) => (receiversValid = receiversValid && emailRegex.test(e))
    );
    let filesValid = files.length > 0;
    files.forEach(
      (f) => (filesValid = filesValid && (!f || fileRegex.test(f.name)))
    );

    setChipsValid(receiversValid);
    if (!emailValid || !receiversValid || !filesValid) {
      setBtnLoading(false);
      return;
    }

    createAnonymousProject(
      localStorage.getItem("auth") || sessionStorage.getItem("auth"),
      files.filter((f) => !!f),
      fileTypes.filter((t) => !!t),
      state.email,
      emails.filter((m) => !!m),
      () => {
        setBtnLoading(false);
        setDone(true);
      },
      () => setBtnLoading(false)
    );
  };

  const anonVerifyCode = (code) => {
    if (!/^\d{6}$/.test(code)) return;
    setBtnLoading(true);

    verifyUpload(
      code,
      files.filter((f) => !!f),
      fileTypes.filter((t) => !!t),
      state.email,
      emails.filter((m) => !!m),
      () => {
        setBtnLoading(false);
        setDone(true);
      },
      () => setBtnLoading(false)
    );
  };

  const reset = () => {
    setDone(false);
    setPending(false);
    setEmails([]);
    setFiles([]);
    setFileTypes([]);
  };

  return (
    <BannerWrapper ref={drop}>
      <Container>
        <ContentArea>
          <Fade bottom delay={30}>
            <HighlightedText>
              <strong>{discount}</strong> {discountLabel}
            </HighlightedText>
            <Heading as="h1" content={title} />
            <Text content={text} />
            {done ? (
              <SuccessBox onClick={reset} />
            ) : pending ? (
              <PendingBox
                email={state.email}
                onClickSend={anonVerifyCode}
                onClickBack={() => setPending(false)}
                isLoading={btnLoading}
              />
            ) : (
              <UploadForm
                files={files}
                emails={emails}
                onSubmit={!!props.email ? submitForm : anonSubmitForm}
                onFileChange={handleFilesUpload}
                onFileDelete={handleFileDelete}
                onTypeSelect={handleFileSelectType}
                onEmailChange={handleChange}
                emailValue={state.email}
                emailDisabled={!!props.email}
                emailValid={state.valid}
                onChipAdd={handleAddChip}
                onChipChange={handleChangeChip}
                onChipDelete={handleDeleteChip}
                chipPlaceholder={placeholder}
                chipsValid={chipsValid}
                isLoading={btnLoading}
                openModal={props.openModal}
              />
            )}
          </Fade>
        </ContentArea>
        {/* End of content section */}

        <CarouselArea>
          {loading ? (
            <GlideCarousel
              carouselSelector="interior_carousel"
              options={glideOptions}
              nextButton={<span className="next_arrow" />}
              prevButton={<span className="prev_arrow" />}
            >
              <Fragment>
                {carousel.map((item) => (
                  <GlideSlide key={`carousel_key${item.id}`}>
                    <a className="item_wrapper" href={item.link}>
                      <Image src={item.thumb_url} alt={item.title} />
                      <Heading as="h4" content={item.title} />
                    </a>
                  </GlideSlide>
                ))}
              </Fragment>
            </GlideCarousel>
          ) : (
            <CircleLoader>
              <div className="circle"></div>
              <div className="circle"></div>
            </CircleLoader>
          )}
        </CarouselArea>
        {/* End of carousel section */}
      </Container>
      <Backdrop active={isActive} />
    </BannerWrapper>
  );
};

export default Banner;
