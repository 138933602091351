import React from "react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import MaterialTable from "material-table";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";

import {
  getFiles,
  deleteFile,
  updateFile,
  updateProject,
  deleteProject,
} from "../../../common/src/api";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& div": {
      width: "100%",
    },
  },
}))(MuiAccordionDetails);

const ProjectBar = (props) => {
  const jwt = localStorage.getItem("auth") || sessionStorage.getItem("auth");
  const [loading, setLoading] = React.useState(true);
  const [models, setModels] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [name, setName] = React.useState(props.name);
  const [newName, setNewName] = React.useState(props.name);
  const { id, onAdd, onOpen, onDelete, onClickSensors } = props;

  React.useEffect(() => {
    if (expanded) {
      const interval = setInterval(() => {
        getFiles(jwt, id, (res) => {
          const info = formatData(res);
          setModels(info);
        });
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [expanded, id, jwt]);

  const refreshFiles = () => {
    setLoading(true);
    getFiles(jwt, id, (res) => {
      const info = formatData(res);
      setLoading(false);
      setModels(info);
    });
  };

  const formatData = (data) =>
    data.map((file) => ({
      id: file.id,
      tag: file.type,
      name: file.name,
      filename: file.original_name,
      loading: file.loading,
      status: file.error ? (
        <div className="d-flex align-items-center" style={{ color: "#f50057" }}>
          <ErrorOutlineIcon fontSize="small" />{" "}
          <span className="ml-2">Error</span>
        </div>
      ) : file.loading ? (
        <div className="d-flex align-items-center">
          <CircularProgress style={{ margin: 2 }} color="inherit" size={16} />
          <span className="ml-2">Cargando</span>
        </div>
      ) : (
        <div className="d-flex align-items-center" style={{ color: "#20e43b" }}>
          <CheckCircleOutlineOutlinedIcon fontSize="small" />{" "}
          <span className="ml-2">Listo</span>
        </div>
      ),
    }));

  const handleExpand = (value) => {
    setExpanded(value);
    value ? refreshFiles() : setLoading(true);
  };

  return (
    <Accordion expanded={expanded} onChange={() => handleExpand(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center">
            {editing ? (
              <>
                <TextField
                  label="Nombre del proyecto"
                  value={newName}
                  onClick={(event) => event.stopPropagation()}
                  onChange={(event) => setNewName(event.target.value)}
                />
                <IconButton
                  className="ml-2"
                  onClick={(event) => {
                    event.stopPropagation();
                    updateProject(jwt, id, { name: newName }, () => {
                      setName(newName);
                      setEditing(false);
                    });
                  }}
                >
                  <CheckIcon color="action" fontSize="small" />
                </IconButton>
                <IconButton
                  className="ml-2"
                  onClick={(event) => {
                    event.stopPropagation();
                    setEditing(false);
                    setNewName(name);
                  }}
                >
                  <CloseIcon color="action" fontSize="small" />
                </IconButton>
              </>
            ) : deleting ? (
              <>
                <Typography variant="h6">
                  ¿Desea eliminar este proyecto?
                </Typography>
                <IconButton
                  className="ml-2"
                  onClick={(event) => {
                    event.stopPropagation();
                    deleteProject(jwt, id, () => onDelete(props.index));
                  }}
                >
                  <CheckIcon color="action" fontSize="small" />
                </IconButton>
                <IconButton
                  className="ml-2"
                  onClick={(event) => {
                    event.stopPropagation();
                    setDeleting(false);
                  }}
                >
                  <CloseIcon color="action" fontSize="small" />
                </IconButton>
              </>
            ) : (
              <>
                <h5>{name}</h5>
                <IconButton
                  className="ml-3"
                  onClick={(event) => {
                    event.stopPropagation();
                    setEditing(true);
                  }}
                >
                  <EditIcon color="action" fontSize="small" />
                </IconButton>
                <IconButton
                  className="ml-2"
                  onClick={(event) => {
                    event.stopPropagation();
                    setDeleting(true);
                  }}
                >
                  <DeleteIcon color="action" fontSize="small" />
                </IconButton>
              </>
            )}
          </div>
          <div
            className="d-flex justify-content-around"
            style={{ width: "120px" }}
          >
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleExpand(true);
                onAdd(refreshFiles);
              }}
            >
              <AddCircleIcon />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                onOpen();
              }}
            >
              <OpenInNewIcon />
            </IconButton>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <MaterialTable
          title=""
          isLoading={loading}
          columns={[
            {
              field: "tag",
              title: "Tipo",
              lookup: { ARC: "ARC", STR: "STR", MEP: "MEP" },
            },
            {
              field: "name",
              title: "Nombre",
            },
            {
              field: "filename",
              title: "Archivo",
              editable: "never",
            },
            {
              field: "status",
              title: "Estado",
              editable: "never",
              sorting: false,
            },
          ]}
          data={models}
          editable={{
            onRowDelete: (oldData) =>
              deleteFile(jwt, oldData.id, () => {
                const index = oldData.tableData.id;
                let newModels = models;
                delete newModels[index];
                newModels = newModels.filter((model) => !!model);
                setModels(newModels);
              }),
          }}
          cellEditable={{
            cellStyle: {},
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              const data = {};
              data[columnDef.field] = newValue;
              return updateFile(jwt, rowData.id, data, (data) => {
                const index = rowData.tableData.id;
                let newModels = models;
                let model = newModels[index];
                model[columnDef.field] = newValue;
                newModels[index] = model;
                setModels(newModels);
              });
            },
          }}
          options={{
            toolbar: false,
            showTitle: false,
            paging: false,
            actionsColumnIndex: 4,
          }}
          localization={{
            header: {
              actions: "Acciones",
            },
            body: {
              deleteTooltip: "Eliminar",
              editTooltip: "Editar",
              emptyDataSourceMessage:
                "No se han cargado modelos a este proyecto.",
              editRow: {
                deleteText: "¿Desea eliminar este modelo?",
                cancelTooltip: "Cancelar",
                saveTooltip: "Continuar",
              },
            },
          }}
          actions={[
            {
              icon: "sensors",
              tooltip: "Sensores",
              onClick: (event, rowData) => onClickSensors(rowData.id),
            },
          ]}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default ProjectBar;
