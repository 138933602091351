import React from "react";
import PropTypes from "prop-types";

const BlogPost = ({
  className,
  thumbUrl,
  thumbClass,
  title,
  excerpt,
  link,
  svgStyle,
}) => {
  // Add all classes to an array
  const addAllClasses = ["blog_post"];
  const addAllClassesThumb = ["thumbnail"];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  if (thumbClass) {
    addAllClassesThumb.push(thumbClass);
  }

  return (
    <div className={addAllClasses.join(" ")}>
      <div className={addAllClassesThumb.join(" ")}>
        <img src={thumbUrl} alt={title} style={svgStyle} />
      </div>
      <div className="content">
        <h3 className="title">{title}</h3>
        <p className="excerpt">{excerpt}</p>
        {link && <div className="learn_more">{link}</div>}
      </div>
    </div>
  );
};

BlogPost.propTypes = {
  className: PropTypes.string,
  thumbUrl: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  link: PropTypes.element,
  thumbClass: PropTypes.string,
  svgStyle: PropTypes.object,
};

export default BlogPost;
