import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { getSensors, saveSensors } from "../../../common/src/api";
import BackdropMenu from "../../../containers/Viewer/common/BackdropMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menu: {
    "& div .MuiDialog-paperScrollPaper": {
      width: 600,
      minHeight: 180,
    },
  },
  menuText: {
    "& div .MuiDialog-paperScrollPaper": {
      width: 600,
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function Sensors({ modelId, open, onClose }) {
  const jwt = localStorage.getItem("auth") || sessionStorage.getItem("auth");
  const classes = useStyles();
  const [sensors, setSensors] = useState({});

  useEffect(() => {
    if (open) getSensors(jwt, modelId, (res) => setSensors(res));
    else setSensors([]);
  }, [open]);

  const handleUrlChange = (guid, newValue) => {
    const newSensors = { ...sensors };
    newSensors[guid].url = newValue;
    setSensors(newSensors);
  };

  return Object.keys(sensors).length > 0 ? (
    <BackdropMenu
      title="Sensores"
      open={open}
      okButton={{
        label: "Guardar",
        onClick: () => saveSensors(jwt, modelId, sensors, onClose),
      }}
      handleClose={onClose}
      className={classes.menu}
    >
      {Object.keys(sensors).map((guid) => (
        <Grid container key={guid} className="align-items-end my-2">
          <Grid item xs="auto" className="mr-3" rowSpacing={3}>
            <Typography>{sensors[guid].name}</Typography>
          </Grid>
          <Grid item xs>
            <TextField
              fullWidth
              label="URL"
              value={sensors[guid].url}
              onChange={(e) => handleUrlChange(guid, e.target.value)}
            />
          </Grid>
        </Grid>
      ))}
    </BackdropMenu>
  ) : (
    <BackdropMenu
      title="Sensores"
      open={open}
      okButton={{
        label: "Cerrar",
        onClick: onClose,
      }}
      handleClose={onClose}
      className={classes.menuText}
      hideCancel
    >
      <Typography>No se han encontrado sensores para este modelo.</Typography>
    </BackdropMenu>
  );
}
