import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Icon } from "react-icons-kit";
import { androidClose } from "react-icons-kit/ionicons/androidClose";
import NavbarWrapper from "../../../common/src/components/Navbar";
import Drawer from "../../../common/src/components/Drawer";
import Button from "../../../common/src/components/Button";
import Logo from "../../../common/src/components/UIElements/Logo";
import HamburgMenu from "../../../common/src/components/HamburgMenu";
import ScrollSpyMenu from "../../../common/src/components/ScrollSpyMenu";
import { DrawerContext } from "../../../common/src/contexts/DrawerContext";
import { Container } from "./navbar.style";
//import SearchPanel from "../SearchPanel";
import LoginModal from "../LoginModal";
import Copyright from "../Copyright";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";

import { menuData } from "../../../common/src/data/Interior";
import colors from "../../../common/src/theme/interior/colors";
import logo1 from "../../../common/src/assets/image/interior/logo_bim_share_b.png";
import logo2 from "../../../common/src/assets/image/interior/logo_bim_share_w.png";

import {
  authenticate,
  createUser,
  verifyRegister,
} from "../../../common/src/api";
import { useHistory } from "react-router-dom";

const Navbar = ({
  stickyActive,
  openModal,
  tabIndex,
  user,
  navbarStyle,
  logoStyle,
  ...props
}) => {
  const history = useHistory();
  const { state, dispatch } = useContext(DrawerContext);
  const [openLoginModal, setOpenLoginModal] = React.useState(!!openModal);
  const emailRegex = /^\w+([.\-+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  /*const [openSearchModal, setOpenSearchModal] = React.useState(false);
  
  const handleSearchModal = () => {
    setOpenSearchModal(!openSearchModal);
  };
  */

  React.useEffect(() => {
    setOpenLoginModal(!!openModal);
  }, [openModal]);

  const handleLoginModalOpen = () => {
    setOpenLoginModal(true);
  };

  const handleLoginModalClose = () => {
    props.onCloseModal();
    setOpenLoginModal(false);
  };

  const handleLogin = (email, password, remember, onFailure) => {
    let emailValid = emailRegex.test(email);
    if (!emailValid) return;

    authenticate(
      email,
      password,
      remember,
      () => history.push("/projects"),
      onFailure
    );
  };

  const handleRegister = (
    code,
    name,
    email,
    profession,
    sector,
    password,
    onFailure
  ) => {
    let emailValid = emailRegex.test(email);
    if (!emailValid || !/^\d{6}$/.test(code)) return;

    verifyRegister(
      code,
      email,
      password,
      name,
      profession,
      sector,
      () => history.push("/projects"),
      onFailure
    );
  };

  const toggleHandler = () => {
    dispatch({
      type: "TOGGLE",
    });
  };

  return (
    <NavbarWrapper {...navbarStyle}>
      <>
        <Container>
          <Logo
            href="/"
            logoSrc={stickyActive ? logo1 : logo2}
            title="BIMshare"
            logoStyle={logoStyle}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            {/*
            <Button
              variant="textButton"
              onClick={handleSearchModal}
              icon={<i className="flaticon-magnifying-glass" />}
              aria-label="search button"
            />
            */}
            <Button
              variant="textButton"
              onClick={
                props.connected
                  ? () => history.push("/projects")
                  : handleLoginModalOpen
              }
              icon={<i className="flaticon-user" />}
              aria-label="registration button"
            />
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <button
                type="button"
                className={state.isOpen ? "active" : ""}
                onClick={toggleHandler}
                aria-label="drawer toggle button"
              >
                <Icon icon={androidClose} />
              </button>
              <ScrollSpyMenu
                menuItems={menuData}
                drawerClose={true}
                offset={-100}
              />
              <Copyright />
            </Drawer>
          </div>
        </Container>
        {/*
        <Modal
          open={openSearchModal}
          onClose={handleSearchModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
            <div>
          <Fade in={openSearchModal}>
            <SearchPanel />
          </Fade>
            </div>
        </Modal>
        */}
        <Modal
          open={openLoginModal}
          onClose={handleLoginModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{ overflow: "auto" }}
        >
          <Fade in={openLoginModal}>
            <>
              <LoginModal
                style={{ maxWidth: 500 }}
                email={user}
                tabIndex={tabIndex}
                row={{ style: { position: "relative" } }}
                onClose={handleLoginModalClose}
                onClickLogin={handleLogin}
                onClickRegister={createUser}
                onClickVerify={handleRegister}
                outlineBtnStyle={{
                  style: {
                    textTransform: "none",
                    fontWeight: "normal",
                    color: colors.link,
                  },
                }}
                googleButtonStyle={{
                  style: {
                    textTransform: "none",
                    color: "#343D48",
                    marginTop: 32,
                  },
                }}
              />
            </>
          </Fade>
        </Modal>
      </>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: "70px",
  },
  logoStyle: {
    width: "128px",
    height: "auto",
  },
};

export default Navbar;
