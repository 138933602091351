/* eslint-disable */
import React, { useState } from "react";
import InputField from "./input.style";
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { file } from "jszip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ClearIcon from "@material-ui/icons/Clear";
import Grow from "@material-ui/core/Grow";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import { interiorTheme } from "../../theme/interior";

const useStyles = makeStyles({
  root: {
    "& .MuiTypography-root": {
      position: "relative",
      left: -40,
      transition: "0.3s",
    },
    "&:hover": {
      "& .MuiTypography-root": {
        left: -20,
      },
    },
  },
  outlined: {
    paddingTop: 14,
    paddingBottom: 14,
  },
});

const FileListItem = ({ name, size, index, deleted, onDelete, onSelect }) => {
  const classes = useStyles();
  const options = ["ARC", "MEP", "STR"];
  const [value, setValue] = useState(options[0]);
  const [hovered, setHovered] = useState(false);
  const [selecting, setSelecting] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (onSelect) onSelect(index, event.target.value);
  };

  const handleOpen = () => {
    setSelecting(true);
  };

  const handleClose = () => {
    setSelecting(false);
    setHovered(false);
  };

  const handleEnter = (event) => {
    setHovered(true);
  };

  const handleLeave = (event) => {
    setHovered(false);
  };

  const handleDelete = () => {
    if (onDelete) onDelete(index);
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  if (deleted) return null;
  return (
    <ThemeProvider theme={interiorTheme}>
      <ListItem
        className={classes.root}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
      >
        <Grow in={hovered && !selecting}>
          <ListItemIcon>
            <IconButton size="small" onClick={handleDelete}>
              <ClearIcon fontSize="small" />
            </IconButton>
          </ListItemIcon>
        </Grow>
        <ListItemText
          className="m-0"
          primary={name}
          secondary={formatBytes(size)}
        />
        <Tooltip
          arrow
          placement="right"
          title={
            <>
              <Typography color="inherit">ARC: Arquitectura</Typography>
              <Typography color="inherit">MEP: Especialidades</Typography>
              <Typography color="inherit">STR: Estructura</Typography>
            </>
          }
        >
          <Select
            classes={{ outlined: classes.outlined }}
            style={{ width: 85 }}
            onChange={handleChange}
            onOpen={handleOpen}
            onClose={handleClose}
            value={value}
            variant="outlined"
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
      </ListItem>
    </ThemeProvider>
  );
};

const FileList = ({ files, onDelete, onSelect }) => {
  if (files.filter((e) => e).length === 0) return null;
  return (
    <>
      <List
        component="nav"
        aria-label="main mailbox folders"
        style={{
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
          background: "white",
          paddingBottom: 0,
        }}
      >
        {files.map((file, index) =>
          !file ? null : (
            <React.Fragment key={index}>
              <FileListItem
                name={file.name}
                size={file.size}
                index={index}
                onDelete={onDelete}
                onSelect={onSelect}
              />
              <Divider />
            </React.Fragment>
          )
        )}
      </List>
    </>
  );
};

const FileInput = ({
  files,
  onChange,
  onDelete,
  onSelect,
  accept,
  multiple,
  required,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ["reusecore__input"];
  const fileListExist = files.filter((e) => e).length > 0;

  const handleOnChange = (e) => {
    onChange(e);
  };

  const handleOnClick = () =>
    document.getElementById("hidden-file-input").click();

  return (
    <InputField className={`${addAllClasses.join(" ")}`}>
      <FileList files={files} onDelete={onDelete} onSelect={onSelect} />

      <Button
        onClick={handleOnClick}
        component="span"
        style={{
          background: "white",
          borderTopLeftRadius: fileListExist ? 0 : 4,
          borderTopRightRadius: fileListExist ? 0 : 4,
          paddingTop: fileListExist ? 11 : 22,
          paddingBottom: fileListExist ? 11 : 22,
          paddingLeft: 22,
          paddingRight: 22,
          width: "100%",
          justifyContent: "left",
          textTransform: "none",
        }}
        size="large"
        type="file"
        startIcon={<AddCircleIcon fontSize="large" />}
      >
        <span
          style={{
            fontSize: fileListExist ? 14 : 16,
            fontWeight: fileListExist ? 400 : 500,
          }}
        >
          Añade {fileListExist ? "más" : "tus"} archivos
        </span>
      </Button>
      <input
        type="file"
        style={{ opacity: 0, height: 0, padding: 0 }}
        id="hidden-file-input"
        onChange={handleOnChange}
        accept={accept}
        multiple={multiple}
        required={required}
      />
      {<span className="highlight" />}
    </InputField>
  );
};

export default FileInput;
