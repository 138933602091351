import React from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Pagination from "@material-ui/lab/Pagination";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { StyledListItemSelect } from "../containers/Viewer/common/StyledListItem";
import BackdropMenu from "../containers/Viewer/common/BackdropMenu";
import ProjectBar from "../containers/Viewer/common/ProjectBar";
import { useHistory } from "react-router-dom";
import { createProject, getProjects, uploadFile } from "../common/src/api";
import theme from "../common/src/theme/material";
import Sensors from "../containers/Manager/Sensors";

import logo from "../common/src/assets/image/interior/logo_bim_share_w.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menu: {
    "& div .MuiDialog-paperScrollPaper": {
      width: 400,
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function ModelUploaderProjects(props) {
  const jwt = localStorage.getItem("auth") || sessionStorage.getItem("auth");
  const history = useHistory();
  const classes = useStyles();
  const [authorized, setAuthorized] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [projects, setProjects] = React.useState([]);
  const [projectsBuffer, setProjectsBuffer] = React.useState([]);
  const [openNewProject, setOpenNewProject] = React.useState(false);
  const [openNewModel, setOpenNewModel] = React.useState(false);
  const [openEditSensors, setOpenEditSensors] = React.useState(false);
  const [projectName, setProjectName] = React.useState("");
  const [modelName, setModelName] = React.useState("");
  const [currentProject, setCurrentProject] = React.useState(-1);
  const [modelType, setModelType] = React.useState("");
  const [sensorsModelId, setSensorsModelId] = React.useState(-1);
  const bufferSize = 8;

  React.useEffect(() => {
    getProjects(jwt, (res) => setProjects(res.reverse()));
  }, [jwt]);

  React.useEffect(() => {
    setProjectsBuffer(projects.slice(0, bufferSize));
  }, [projects]);

  React.useEffect(() => {
    localStorage.getItem("auth") || sessionStorage.getItem("auth")
      ? setAuthorized(true)
      : history.replace("/");
  }, [history]);

  const handlePageChange = (event, value) => {
    setProjectsBuffer(
      projects.slice(
        (value - 1) * bufferSize,
        bufferSize + (value - 1) * bufferSize
      )
    );
  };

  const handleProjectDelete = (i) => {
    let newProjects = projects;
    delete newProjects[i];
    setProjects(newProjects.filter((p) => !!p));
  };

  const signOut = () => {
    localStorage.removeItem("auth");
    sessionStorage.removeItem("auth");
    history.push("/");
  };

  const handleOpenNewModelMenu = (id, callback) => {
    if (!loading) {
      setOpenNewModel(!openNewModel);
      setCurrentProject(id);
      setModelName("");
    }
  };

  const handleOpenNewPojectMenu = () => {
    setOpenNewProject(!openNewProject);
    setProjectName("");
  };

  const handleOpenEditSensors = (id) => {
    setOpenEditSensors(!openEditSensors);
    id ? setSensorsModelId(id) : setSensorsModelId(-1);
  };

  const handleProjectName = (event) => {
    setProjectName(event.target.value);
  };

  const handleModelName = (event) => {
    setModelName(event.target.value);
  };

  const handleModelType = (event) => {
    setModelType(event.target.value);
  };

  const selectFile = (event) => {
    setFile(event.target.files[0]);
  };

  const sendFile = (event) => {
    if (file && modelType && currentProject && modelName) {
      setLoading(true);
      uploadFile(jwt, currentProject, modelName, file, modelType, () => {
        setLoading(false);
        handleOpenNewModelMenu();
      });
    }
  };

  const onNewProject = (event) => {
    createProject(jwt, projectName, (res) => {
      setProjects(res.reverse());
      setOpenNewProject(false);
    });
  };

  return !authorized ? null : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        background: "white",
      }}
    >
      <ThemeProvider theme={theme}>
        <AppBar position="static">
          <Toolbar style={{ padding: 20 }}>
            <div className={classes.title}>
              <a href="/">
                <img alt="" src={logo} width={128} />
              </a>
            </div>
            <Button color="inherit" onClick={handleOpenNewPojectMenu}>
              Nuevo proyecto
            </Button>
            <Button color="inherit" onClick={signOut}>
              Salir
            </Button>
          </Toolbar>
        </AppBar>
        <Grid
          container
          alignContent="space-between"
          className="py-4"
          style={{ flexGrow: 1 }}
        >
          <Grid item container justify="center" alignContent="flex-start">
            {projectsBuffer.map((project, i) => (
              <Grid key={project.uuid} item xs={9} className="mb-4">
                <ProjectBar
                  index={i}
                  onDelete={handleProjectDelete}
                  id={project.uuid}
                  name={project.name}
                  onAdd={(callback) =>
                    handleOpenNewModelMenu(project.uuid, callback)
                  }
                  onOpen={() => window.open(`/${project.uuid}`)}
                  onClickSensors={handleOpenEditSensors}
                />
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12}>
            <Pagination
              className={classes.pagination}
              onChange={handlePageChange}
              count={Math.ceil(projects.length / bufferSize)}
              color="primary"
            />
          </Grid>
        </Grid>

        <BackdropMenu
          title="Nuevo modelo"
          open={openNewModel}
          disabled={loading}
          okButton={{
            label: "Cargar",
            onClick: sendFile,
            disabled: !modelType || !file || !modelName,
          }}
          handleClose={handleOpenNewModelMenu}
          className={classes.menu}
        >
          <StyledListItemSelect
            label="Tipo"
            onChange={handleModelType}
            value={modelType}
            options={[
              {
                label: "ARC",
                value: "ARC",
              },
              {
                label: "STR",
                value: "STR",
              },
              {
                label: "MEP",
                value: "MEP",
              },
            ]}
          />
          <div className="my-3" style={{ paddingLeft: 16, paddingRight: 16 }}>
            <input type="file" onChange={selectFile} accept=".ifc, .ifczip" />
          </div>
          <div style={{ paddingLeft: 16, paddingRight: 16 }}>
            <TextField
              onChange={handleModelName}
              fullWidth
              label="Nombre"
              value={modelName}
            />
          </div>
          <div className="pt-4 pb-3" style={loading ? {} : { display: "none" }}>
            <LinearProgress />
          </div>
        </BackdropMenu>
        <BackdropMenu
          title="Nuevo proyecto"
          open={openNewProject}
          okButton={{
            label: "Crear",
            onClick: onNewProject,
            disabled: !projectName,
          }}
          handleClose={handleOpenNewPojectMenu}
          className={classes.menu}
        >
          <TextField
            onChange={handleProjectName}
            fullWidth
            label="Nombre"
            value={projectName}
          />
        </BackdropMenu>
        <Sensors
          modelId={sensorsModelId}
          open={openEditSensors}
          onClose={handleOpenEditSensors}
        />
      </ThemeProvider>
    </div>
  );
}
