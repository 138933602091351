import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Viewer from "./pages/Viewer";
import Landing from "./pages/Landing";
import ProjectsManager from "./pages/ProjectsManager";

export default class App extends React.Component {
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route
            path="/login"
            render={(props) => <Landing openModal tabIndex={0} {...props} />}
          />
          <Route
            path="/register"
            render={(props) => <Landing openModal tabIndex={1} {...props} />}
          />
          <Route
            path="/projects"
            render={(props) => <ProjectsManager {...props} />}
          />
          <Route path="/:hash" render={(props) => <Viewer {...props} />} />
          <Route path="/" render={(props) => <Landing {...props} />} />
        </Switch>
      </Router>
    );
  }
}
