import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/TabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";
import Box from "../../../common/src/components/Box";
import Text from "../../../common/src/components/Text";
import Heading from "../../../common/src/components/Heading";
import Input from "../../../common/src/components/Input";
import CheckBox from "../../../common/src/components/Checkbox/index";
import Button from "../../../common/src/components/Button";
//import Image from "../../../common/src/components/Image";
import colors from "../../../common/src/theme/interior/colors";
import { AuthWrapper, LoginModalWrapper } from "./loginModal.style";
import "rc-tabs/assets/index.css";
//import LogoImage from "../../../common/src/assets/image/interior/logo.png";
//import LoginImage from "../../../common/src/assets/image/interior/login-bg.jpg";
//import GoogleLogo from "../../../common/src/assets/image/interior/google-icon.jpg";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import ReactInputVerificationCode from "react-input-verification-code";

const Feedback = ({ active, ...props }) => {
  const style = {
    color: colors.error,
    display: active ? "block" : "none",
  };
  return (
    <div className="p-3 text-center">
      <span style={style}>{props.children}</span>
    </div>
  );
};

const PendingBox = (props) => {
  const [auth, setAuth] = React.useState("");

  const ButtonGroup = () => (
    <Fragment>
      <Button
        title="Volver"
        variant="textButton"
        onClick={props.onClickBack}
        {...props.outlineBtnStyle}
      />
      <Button
        className="default"
        title="VERIFICAR"
        type="submit"
        isLoading={props.isLoading}
        disabled={props.isLoading}
        onClick={() => props.onClickSend(auth)}
        {...props.btnStyle}
      />
    </Fragment>
  );

  return (
    <div style={{ borderRadius: 4, background: "white", padding: 24 }}>
      <Heading className="text-center" as="h3" content={"Verifica tu email"} />
      <Text
        className="text-center"
        style={{ color: "#888" }}
        content={`Hemos enviado un código a ${props.email}.`}
      />
      <AuthWrapper>
        <ReactInputVerificationCode
          placeholder="-"
          length={6}
          onChange={setAuth}
        />
      </AuthWrapper>
      <div className="d-flex justify-content-around">
        <ButtonGroup />
      </div>
    </div>
  );
};

const LoginModal = ({
  row,
  col,
  btnStyle,
  logoStyle,
  titleStyle,
  contentWrapper,
  outlineBtnStyle,
  descriptionStyle,
  googleButtonStyle,
  onClickLogin,
  onClickForgot,
  onClickRegister,
  onClickVerify,
  ...props
}) => {
  // Login values
  const [feedbackActive, setFeedbackActive] = React.useState(false);
  const [username, setUsername] = React.useState(props.email || "");
  const [passwordLogin, setPasswordLogin] = React.useState("");
  const [remember, setRemember] = React.useState("");
  const [btnLoading, setBtnLoading] = React.useState(false);

  // Register values
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState(props.email || "");
  const [profession, setProfession] = React.useState("");
  const [sector, setSector] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [pending, setPending] = React.useState(false);

  const defaultTab = (() => {
    switch (props.tabIndex) {
      case 0:
        return "loginForm";
      case 1:
        return "registerForm";
      default:
        return "loginForm";
    }
  })();

  const onSubmitVerify = (code) => {
    setBtnLoading(true);
    onClickVerify(code, name, email, profession, sector, password, () =>
      setBtnLoading(false)
    );
  };

  const onSubmitRegister = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    onClickRegister(
      email,
      () => {
        setBtnLoading(false);
        setPending(true);
      },
      () => setBtnLoading(false)
    );
  };

  const onSubmitLogin = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    onClickLogin(username, passwordLogin, remember, () => {
      setBtnLoading(false);
      setFeedbackActive(true);
    });
  };

  const LoginButtonGroup = () => (
    <Fragment>
      <Button
        className="default"
        title="INICIAR SESIÓN"
        type="submit"
        isLoading={btnLoading}
        {...btnStyle}
      />
      <Button
        title="Olvidé mi contraseña"
        variant="textButton"
        {...outlineBtnStyle}
        onClick={onClickForgot}
      />
    </Fragment>
  );
  const SignupButtonGroup = () => (
    <Fragment>
      <Button
        className="default"
        title="REGISTRARSE"
        type="submit"
        isLoading={btnLoading}
        disabled={props.isLoading}
        loaderColor={colors.heading}
        {...btnStyle}
      />
    </Fragment>
  );

  return (
    <LoginModalWrapper {...props}>
      <Box className="row" {...row}>
        <IconButton
          onClick={props.onClose}
          style={{ position: "absolute", right: 25, top: 10, zIndex: 1 }}
        >
          <CloseIcon />
        </IconButton>

        <Box className="col tabCol" {...col}>
          <Box {...contentWrapper}>
            <Tabs
              defaultActiveKey={defaultTab}
              renderTabBar={() => <ScrollableInkTabBar />}
              renderTabContent={() => <TabContent />}
            >
              <TabPane tab="INICIAR SESIÓN" key="loginForm">
                {/*
                <Button
                  icon={<Image src={GoogleLogo} alt="Google Icon" />}
                  title="Iniciar sesión con Google"
                  iconPosition="left"
                  className="google-login__btn"
                  {...googleButtonStyle}
                />*/}
                <form onSubmit={onSubmitLogin} className="mt-5">
                  <Input
                    value={username}
                    onChange={setUsername}
                    inputType="email"
                    isMaterial
                    label="Correo electrónico"
                    required
                  />
                  <Input
                    value={passwordLogin}
                    onChange={setPasswordLogin}
                    inputType="password"
                    isMaterial
                    label="Contraseña"
                    passwordShowHide
                    required
                  />
                  <CheckBox
                    checked={remember}
                    onChange={(e) => setRemember(e.target.checked)}
                    id="remember"
                    htmlFor="remember"
                    labelText="Mantener la sesión iniciada"
                  />
                  <div className="d-flex justify-content-around">
                    <LoginButtonGroup />
                  </div>
                </form>
                <Feedback active={feedbackActive}>
                  Usuario o contraseña incorrectos.
                </Feedback>
              </TabPane>
              <TabPane tab="REGISTRARSE" key="registerForm">
                {/*
                <Button
                  icon={<Image src={GoogleLogo} alt="Google Icon" />}
                  title="Registarse con Google"
                  iconPosition="left"
                  className="google-login__btn"
                  {...googleButtonStyle}
                />*/}
                {pending ? (
                  <PendingBox
                    email={email}
                    btnStyle={btnStyle}
                    outlineBtnStyle={outlineBtnStyle}
                    onClickBack={() => setPending(false)}
                    onClickSend={onSubmitVerify}
                    isLoading={btnLoading}
                    loaderColor={colors.heading}
                  />
                ) : (
                  <form onSubmit={onSubmitRegister} className="mt-5">
                    <Input
                      value={name}
                      onChange={setName}
                      inputType="text"
                      isMaterial
                      label="Nombre"
                      required
                    />
                    <Input
                      value={profession}
                      onChange={setProfession}
                      inputType="text"
                      isMaterial
                      label="Profesión"
                      required
                    />
                    <Input
                      value={sector}
                      onChange={setSector}
                      inputType="text"
                      isMaterial
                      label="Rubro"
                      required
                    />
                    <Input
                      value={email}
                      onChange={setEmail}
                      inputType="email"
                      isMaterial
                      label="Correo electrónico"
                      required
                    />
                    <Input
                      value={password}
                      onChange={setPassword}
                      inputType="password"
                      isMaterial
                      label="Contraseña"
                      required
                      passwordShowHide
                    />
                    <div className="d-flex justify-content-center">
                      <SignupButtonGroup />
                    </div>
                  </form>
                )}
              </TabPane>
            </Tabs>
          </Box>
        </Box>
      </Box>
    </LoginModalWrapper>
  );
};

// LoginModal style props
LoginModal.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  logoStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  hintTextStyle: PropTypes.object,
  contentWrapper: PropTypes.object,
  descriptionStyle: PropTypes.object,
  googleButtonStyle: PropTypes.object,
};

// LoginModal default style
LoginModal.defaultProps = {
  // Team member row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
  },
  // Team member col default style
  col: {
    width: [1, 1 / 2],
  },
  // Default logo size
  logoStyle: {
    width: "128px",
    height: "auto",
    ml: "15px",
  },
  // Title default style
  titleStyle: {
    fontSize: ["22px", "36px", "50px"],
    fontWeight: "400",
    color: "#20201D",
    letterSpacing: "-0.025em",
    mt: "35px",
    mb: "10px",
  },
  // Description default style
  descriptionStyle: {
    color: "rgba(52, 61, 72, 0.8)",
    fontSize: "15px",
    lineHeight: "26px",
    letterSpacing: "-0.025em",
    mb: "23px",
    ml: "1px",
  },
  // Content wrapper style
  contentWrapper: {
    pt: ["32px", "56px"],
    pl: ["17px", "32px", "38px", "40px", "56px"],
    pr: "32px",
    pb: ["32px", "56px"],
  },
  // Default button style
  btnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
  },
  // Outline button outline style
  outlineBtnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#10ac84",
  },
  // Google button style
  googleButtonStyle: {
    bg: "#ffffff",
    color: "#343D48",
  },
};

export default LoginModal;
